import React from 'react';
import './resume.css';

const Resume = () => {
  return (
    <div className="pdf-container">
      <h1>Resume</h1>
    </div>
  );
};

export default Resume;


